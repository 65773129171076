:root {
  --max-mobile-width: 576px;
  --max-tablet-width: 768px;
}

// For media queries
$max-mobile-width: 576px;
$max-tablet-width: 768px;

$primary-color: #5dc1c6;
$accent-color: #34548b;
$warn-color: #ff0000;

@import 'garrampa-theme';
@import 'variables';

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

/*
  CUSTOM STYLES
 */

// DIALOG
.dialog-actions {
  justify-content: flex-end !important;
  padding: 20px !important;
  gap: 12px;
}

// Responsive tables
.table-container {
  overflow: auto;
  width: 100%;
}

button.big-button-with-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 24px;
  height: 60px;
  width: 100%;
  font-weight: 400;
  flex: 1;

  mat-icon {
    font-size: 50px;
    width: max-content;
    height: max-content;
    margin-right: 0;
  }
}
